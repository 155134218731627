import Button from '@global/Button'
import { getAlignmentClass } from '@lib/helpers'
import { Container } from '@styles/global.styled'
import { Link } from '@routes'
import * as S from './FullWidthText.styled'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const FullWidthText = ({ title, alignment, para, link, center }) => {
  const [urlWithPartnerParams, setUrlWithPartnerParams] = useState(null)
  const router = useRouter()

  useEffect(() => {
    if (link?.url) {
      const partnerParam = router?.query?.partner
        ? `partner=${router.query.partner}`
        : ''
      const separator = link.url.includes('?') ? '&' : '?'
      const urlWithParams = partnerParam
        ? `${link.url}${separator}${partnerParam}`
        : link.url

      setUrlWithPartnerParams(urlWithParams)
    }
  }, [link])

  return (
    <Container>
      <S.FullWidthText className={`${getAlignmentClass(alignment)}`}>
        {title && <S.FullWidthTextTitle>{title}</S.FullWidthTextTitle>}
        {para && (
          <S.FullWidthTextDescription
            dangerouslySetInnerHTML={{ __html: para }}
          />
        )}
        {urlWithPartnerParams && (
          <S.FullWidthTextAction>
            <Link route={urlWithPartnerParams} passHref>
              <Button color='secondary'>{link.label}</Button>
            </Link>
          </S.FullWidthTextAction>
        )}
      </S.FullWidthText>
    </Container>
  )
}

FullWidthText.defaultProps = {
  title: 'A brief history of Barry Plant',
  para: '',
  alignment: '',
}

FullWidthText.ApiBlockToProps = (data) => {
  let componentProps = {
    title: data.value.heading,
    alignment: data.value.text_alignment,
    para: data.value.content ? data.value.content : data.value.text,
    color: data.value.text_colour || null,
    link: null,
  }
  if (data.value.call_to_action_external_link) {
    componentProps.link = {
      label: data.value.call_to_action_text,
      url: data.value.call_to_action_external_link,
    }
  }
  if (data.value.call_to_action_link) {
    componentProps.link = {
      label: data.value.call_to_action_text,
      url: data.value.call_to_action_link.path,
    }
  }

  return componentProps
}
export default FullWidthText
