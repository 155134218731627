import Button from '@global/Button'
import { useState } from 'react'
import { getCookie, JWT_COOKIE } from '@lib/cookies'
import * as S from './LoadmoreInfo.styled'

const LoadmoreInfo = ({
  queryParams,
  total,
  numberShowing,
  onSuccess,
  apiCall,
  page,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const token = getCookie(JWT_COOKIE)

  const getListing = async () => {
    try {
      setIsLoading(true)
      const params = { ...queryParams, page: page + 1 }
      const response = await apiCall(params, token)
      const { results, items, next } = response || {}
      onSuccess && onSuccess(results || items, params.page)

      if (!items.length) {
        setIsFinished(true)
      }
    } catch (error) {
      console.error('An error occurred while fetching the data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const visibleItems = total < numberShowing ? total : numberShowing

  return total > 0 ? (
    <S.ResultsMeta>
      <S.ResultsMetaInfo>
        Showing {visibleItems} of {total} results
        <S.ResultsMetaBar width={(visibleItems / total) * 100} />
      </S.ResultsMetaInfo>
      {!isFinished && visibleItems < total && (
        <Button color='secondary' onClick={getListing} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Load More'}
        </Button>
      )}
    </S.ResultsMeta>
  ) : null
}

export default LoadmoreInfo
