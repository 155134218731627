const http_method = 'https'
const hostname = process.env.NEXT_PUBLIC_API_HOSTNAME
  ? process.env.NEXT_PUBLIC_API_HOSTNAME
  : process.env.NODE_ENV === 'production'
  ? 'www.barryplant.com.au'
  : 'barryplant-cms.techequipt.com.au'

const baseURL = `${http_method}://${hostname}/api/`
const authBaseURL = baseURL + 'rest-auth/'
const subscriberBaseURL = baseURL + 'subscribers/'
const settingsBase = baseURL + 'cms/settings/'

export const apis = {
  WAGTAIL_API_BASE: baseURL + 'cms/pages/',
  PROPERTIES_API_BASE: baseURL + 'properties/',
  PROPERTIES_INSPECTION_API: baseURL + 'inspections/',
  PROPERTIES_AUCTION_API: baseURL + 'auctions/',
  TESTIMONIAL_API_BASE: baseURL + 'testimonials/',
  WAGTAIL_SLUG_FIND: baseURL + 'cms/pages/find/',
  WAGTAIL_MAIN_MENU: baseURL + 'cms/pages/main-menus/',
  WAGTAIL_PREVIEW: baseURL + 'cms/pages/preview/',
  OFFICES_API_BASE: baseURL + 'offices/',
  OFFICES_MAP_LOCATIONS: baseURL + 'offices/map-locations/',
  AUTOCOMPLETE: baseURL + 'autocomplete/',
  ALERTS: baseURL + 'alerts/',
  LOGIN: authBaseURL + 'login/',
  LOGOUT: authBaseURL + 'logout/',
  RESET_PASSWORD: authBaseURL + 'password/reset/',
  PASSWORD_CHANGE: authBaseURL + 'password/reset/confirm/',
  REGISTER: authBaseURL + 'registration/',
  USER: authBaseURL + 'user/',
  AGENTS: baseURL + 'agents/',
  CAREERS: baseURL + 'careers/',
  CAREERS_APPLICATION: baseURL + 'careers/application/',
  SUBSCRIBER_PROPERTIES: subscriberBaseURL + 'listings/',
  SUBSCRIBER_OFFICES: subscriberBaseURL + 'offices/',
  SUBSCRIBER_AGENTS: subscriberBaseURL + 'agents/',
  SUBSCRIBER_ALERTS: subscriberBaseURL + 'alerts/',
  SUBSCRIBER_POSTS: subscriberBaseURL + 'posts/',
  SUBSCRIBER_CREATE_FAVOURITE: subscriberBaseURL + 'favourites/',
  FACEBOOK_LOGIN: authBaseURL + 'facebook/',
  FACEBOOK_CONNECT: authBaseURL + 'facebook/connect/',
  GOOGLE_LOGIN: authBaseURL + 'google/',
  GOOGLE_CONNECT: authBaseURL + 'google/connect/',
  SETTINGS: settingsBase,
  SEARCH_FACETS: baseURL + 'search/facets/',
  PROPERTY_ENQUIRY: baseURL + 'property-enquiry/',
  PROJECT_ENQUIRY: baseURL + 'project-enquiry/',
  CMS_FORMS: baseURL + 'forms/',
  CMS_FOOTER_MENUS: baseURL + 'cms/flat-menus/',
  CMS_MAIN_MENU: baseURL + 'cms/main-menus/',
  CMS_OFFICE_MENU: baseURL + 'offices/menus/',
  APPRAISAL_REQUEST: baseURL + 'appraisal-request/',
  SUBURB_DATABASE: baseURL + 'suburbs-database/',
  CMS_SETTINGS: baseURL + 'cms/settings/',
  REDIRECTS: baseURL + 'redirects/',
  AGENT_ENQUIRY: baseURL + 'agent-enquiry/',
  OFFICE_ENQUIRY: baseURL + 'office-enquiry/',
  REPORT_ISSUE: baseURL + 'report-issue/',
  MAINTENANCE_REQUEST: baseURL + 'maintenance-request/',
  LOG_HIT: baseURL + 'properties/log-hit/',
  PROJECTS: baseURL + 'projects/',
  REFERRAL: baseURL + 'referral/',
  REFERRAL_ACCEPT_REJECT: baseURL + 'referral/accept-reject/',
  REFERRAL_CHOICES: baseURL + 'referral/choices/',
  PARTNERS: baseURL + 'partners/',
  SUBSCRIBERS_REFERRAL: baseURL + 'subscribers/referrals/',
}
